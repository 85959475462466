<!-- =========================================================================================
  File Name: UserEdit.vue
  Description: User Edit Page
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="page-user-edit">

    <vs-alert color="danger" title="User Not Found" :active.sync="user_not_found">
      <span>User record with id: {{ $route.params.userId }} not found. </span>
      <span>
        <span>Check </span><router-link :to="{name:'page-user-list'}" class="text-inherit underline">All Users</router-link>
      </span>
    </vs-alert>

    <vx-card v-if="user_data">

      <div slot="no-body" class="tabs-container px-6 pt-6">

        <vs-tabs v-model="activeTab" class="tab-action-btn-fill-conatiner">
          <vs-tab label="Account" icon-pack="feather" icon="icon-user">
            <div class="tab-text">
              <user-edit-tab-account class="mt-4" :data="user_data" />
            </div>
          </vs-tab>
          <vs-tab :disabled="user_data.profileCreatedBy==='student'" label="Students"  icon-pack="feather" icon="icon-info">
            <div class="tab-text">
              <user-edit-tab-information class="mt-4" :data="user_data" />
            </div>
          </vs-tab>
        </vs-tabs>

      </div>

    </vx-card>
  </div>
</template>

<script>
import UserEditTabAccount     from "./UserEditTabAccount.vue"
import UserEditTabInformation from "./UserEditTabInformation.vue"

// Store Module
import moduleUserManagement from '@/store/user-management/moduleUserManagement.js'
import { mapActions } from 'vuex';

export default {
  components: {
    UserEditTabAccount,
    UserEditTabInformation,
  },
  data() {
    return {
      user_data: null,
      user_not_found: false,

      /*
        This property is created for fetching latest data from API when tab is changed

        Please check it's watcher
      */
      activeTab: 0,
    }
  },
  watch: {
    activeTab() {
      let self = this;
      this.fetchUserById(self.$route.params.userId).then(res => {
        self.user_data = res.data.data;
      })
    }
  },
  methods: {
    ...mapActions('tutor', ['fetchTutorById']),
    ...mapActions('user', ['fetchUserById']),
    getTutorDetails() {
      let self = this;
      this.fetchUserById(self.$route.params.userId).then(res => {
        self.user_data = res.data.data;
      })
    },
  },
  async mounted() {
      await this.getTutorDetails()
  }
}

</script>
